.dashboard--header{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 80px;
}

.chart-container{
    height: 450px;
}

.billion-calc{
    height: 225px;
    border: 2px solid #000;
    margin: auto 0;
    padding: 8px 16px;
}